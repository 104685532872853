import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import ContactUsPage from '../pagesComponents/contactUsPage';

const ContactUs = ({ location }) => (
  <Layout location={location}>
    <ContactUsPage />
  </Layout>
);

ContactUs.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContactUs;
