import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Helmet } from 'react-helmet';

import ContentWrapper from '../components/contentWrapper';
import msfMap from '../images/msfMap.png';

import styles from '../styles/pagesComponents/contactUsPage.module.scss';

const ContactUsPage = ({ intl }) => (
  <ContentWrapper>
    <Helmet>
      <title>{intl.formatMessage({ id: 'menu.contactUs' })}</title>
      <meta name="description" content={intl.formatMessage({ id: 'meta.description.contactUs' })} />
      <meta property="og:title" content={intl.formatMessage({ id: 'menu.contactUs' })} />
      <meta property="og:description" content={intl.formatMessage({ id: 'meta.description.contactUs' })} />
    </Helmet>
    <div>
      <h2 className={styles.header}>
        <FormattedMessage
          id="menu.contactUs"
        />
      </h2>
      <div className={styles.content}>
        <form className={styles.form} name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" netlify>
          <label htmlFor="name">
            <FormattedMessage
              id="contact.name"
            />
          </label>
          <input type="text" name="name" id="name" required />
          <label htmlFor="email">
            <FormattedMessage
              id="contact.email"
            />
          </label>
          <input type="email" name="email" id="email" required />
          <label htmlFor="message">
            <FormattedMessage
              id="contact.message"
            />
          </label>
          <textarea name="message" id="message" rows={6} required />
          <div data-netlify-recaptcha />
          <input type="hidden" name="form-name" value="contact" />
          <div className={styles.hidden}>
            <label htmlFor="bot-field">Only for robots</label>
            <input type="text" name="bot-field" id="bot-field" />
          </div>
          <div className={styles.buttonContainer}>
            <button type="submit">
              <FormattedMessage
                id="contact.submit"
              />
            </button>
          </div>
        </form>
        <div className={styles.imgContainer}>
          <img src={msfMap} alt={intl.formatMessage({ id: 'contact.worldMap' })} />
        </div>
      </div>
    </div>
  </ContentWrapper>
);

ContactUsPage.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ContactUsPage);
